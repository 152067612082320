// import config from '@/@/config'
import axiosapi from 'axios'
let webpath = process.env.BASE_URL
// let webpath = config.build.assetsPublicPath
// if (process.env.NODE_ENV === 'devserver') {
//   webpath = config.builddes.assetsPublicPath
// }
let url = window.location.origin + window.location.pathname
// Para que funcione con ruta sin virtual directory (externo)
webpath = url
let apipath = webpath + 'api/'
if (process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'devserver' && process.env.NODE_ENV !== 'int') {
  apipath = 'http://localhost:54972/api/'
  webpath = 'http://localhost:54972/'
}

const baseApiUrl = apipath
const baseWebUrl = webpath
const baseUrl = url
const axios = axiosapi.create({
  // headers: {
  //   'Authorization': null
  // }
})

export const global = {
  baseApiUrl,
  baseWebUrl,
  baseUrl,
  axios
}
