import { global } from './global'
import { store } from '@/store'

let _baseurl = global.baseApiUrl + 'home/'

export default {
  getCarouselItems() {
    return global.axios.get(_baseurl + 'get-carousel-item?canalid=' + store.getters.user.canalid + '&asociadoComisionGroupID=' + store.getters.user.asociado.asociadoComisionGroupID)
  },
  getEsquemaItems() {
    return global.axios.get(_baseurl + 'get-esquema-list?canalid=' + store.getters.user.canalid  + '&asociadoComisionGroupID=' + store.getters.user.asociado.asociadoComisionGroupID)
  },
  descaragrEsquema(documentName) {
    return global.axios.get(_baseurl + 'descargar-esquema?documentName=' + documentName + '&nombreAsociado=' + store.getters.user.nombre, { responseType: 'blob', headers: { 'Accept': 'application/pdf' } })
  },
  getCanales() {
    return global.axios.get(_baseurl + 'get-canales')
  },
  getCanalesInternos() {
    return global.axios.get(_baseurl + 'get-canales-internos')
  },
  getCanalesExternos() {
    return global.axios.get(_baseurl + 'get-canales-externos')
  },
  getCanalesExternosGestionados(corteId) {

    var id = corteId?corteId:0;

    return global.axios.get(_baseurl + 'get-canales-externos-gestionados?corteId=' + id)
  },

}
