import { global } from './global'
import { store } from '@/store'

let _baseurl = global.baseApiUrl + 'asociado/'

let _baseurlAtributos = global.baseApiUrl + 'asociado-atributos/'

export default {
  getSucursales(codasociado) {
    return global.axios.get(_baseurl + 'get-sucursales-by-codigo-asociado?codigoasociado=' + codasociado)
  },
  getCortes(canalID) {
    return global.axios.get(_baseurl + 'get-cortes-by-canal-id?canalid=' + canalID)
  },

  getSucursalesByEmpleados(param) {
    return global.axios.post(_baseurl + 'get-sucursales-por-empleados', param)
  },
  getPeriodo() {
    return global.axios.get(_baseurl + 'get-periodos-calidad')
  },
  getAsociados(canalID) {
    return global.axios.get(_baseurl + 'get-asociado?canalid=' + canalID)
  },
  getAsociadosPorCanales(canales) {
    return global.axios.post(_baseurl + 'get-asociados-por-canales', canales)
  },
  getCanalAsociados(AsociadoID) {
    return global.axios.get(_baseurl + 'get-canal-asociados?asociadoid=' + AsociadoID)
  },
  getSucursalesByCanalAsociadoID(CanalAsociadoID) {
    return global.axios.get(_baseurl + 'get-sucursales-by-canal-asociado-id?canalasociadoid=' + CanalAsociadoID)
  },
  getCanalAsociadosByCodAsociado(CodAsociado) {
    return global.axios.get(_baseurl + 'get-canal-asociados-by-codigo-asociado?codigoasociado=' + CodAsociado)
  },
  getColumnas() {
    return global.axios.get(_baseurlAtributos + 'get-columnas?asociadoId=' + store.getters.user.asociado.id)
  },
  guardarColumnas(columnas) {
    return global.axios.post(_baseurlAtributos + 'guardar-columnas?asociadoId=' + store.getters.user.asociado.id + '&columnas=' + columnas)
  },
  getInformacionAsociado() {

    var id = store.getters.user.asociado.id;

    if(!id){
      id = 0;
    }

    return global.axios.get(_baseurl + 'get-asociado?id=' + id)
  },
  putAsociado(data) {
    return global.axios.post(_baseurl + 'update-asociado', data)
  },
  getAsociadoLogo() {

    var id = store.getters.user.asociado.id;

    if(!id){
      id = 0;
    }

    return global.axios.get(_baseurl + 'get-asociado-logo?asociadoId=' + id, { responseType: 'blob', headers: { 'Accept': 'image/png' } })
  },
  getAsociadoFirma() {

    var id = store.getters.user.asociado.id;

    if(!id){
      id = 0;
    }

    return global.axios.get(_baseurl + 'get-asociado-firma?asociadoId=' +  id, { responseType: 'blob', headers: { 'Accept': 'image/png' } })
  },
  getAsociadoSello() {

    var id = store.getters.user.asociado.id;

    if(!id){
      id = 0;
    }

    return global.axios.get(_baseurl + 'get-asociado-sello?asociadoId=' + id, { responseType: 'blob', headers: { 'Accept': 'image/png' } })
  },
  getAsociadoCobrosFacturas(canalAsociadoID, fecha) {
    return global.axios.get(_baseurl + 'get-asociado-cobros-factura?canalasociadoid=' + canalAsociadoID + '&fecha=' + fecha)
  },
  getAsociadoCobrosFacturasDownload(canalAsociadoID, fecha) {
    var param = { canalasociadoid: canalAsociadoID, fecha: fecha };
    return global.axios.post(_baseurl + 'get-asociado-cobros-factura-download', param, { responseType: 'blob', headers: { 'Accept': 'application/vnd.ms-excel', 'Content-Type': 'application/json' } })
  },
  getGruposComisionesByCanalID(canalID) {
    return global.axios.get(_baseurl + 'get-gruposcomisiones-by-canal-id?canalid=' + canalID)
  },
}
