import Vue from 'vue'
import Router from 'vue-router'
import { store } from './store'
import { global } from './api/global'

Vue.use(Router)

const ifAuthenticated = async (to, from, next) => {
  store.dispatch('setBreadcrumbs', to.meta.header)
  if (store.getters.isAuthenticated) {
    next()
    return
  } else if (await CheckSession()) {
    next()
    return
  }
  next('/login')
}

const ifNotAuthenticated = (to, from, next) => {
  if (!store.getters.isAuthenticated) {
    next()
    return
  }
  next('/')
}

const ifComisiones = (to, from, next) => {
  ifAuthenticated(to, from, next)
  if (store.getters.user && (store.getters.user.rol === 'Comisiones')) {
    next()
    return
  }
  next('/')
}

const CheckSession = async () => {
  if (sessionStorage.getItem('token')) {
    global.axios.defaults.headers.common = { 'Authorization': sessionStorage.getItem('token') }
    if (store.getters.user.nombre == null) {
      await store.dispatch('setUserData', true)
    }
    return true
  }
}

export default new Router({
  routes: [
    {
      path: '*',
      name: 'login',
      redirect: { name: 'Login' }
    },
    {
      path: '/autenticacion',
      name: 'autenticacion',
      component: () => import('./views/autenticacion'),
      beforeEnter: ifAuthenticated
    },
    {
      path: '/',
      name: 'home',
      meta: { header: 'Inicio' },
      component: () => import('./views/home'),
      beforeEnter: ifAuthenticated
    },
    {
      path: '/dashboard',
      name: 'Dashboard',
      meta: { header: 'Dashboard' },
      component: () => import('./views/dashboard'),
      beforeEnter: ifAuthenticated
    },
    {
      path: '/dashboard-empleado',
      name: 'DashboardEmpleado',
      meta: { header: 'Dashboard' },
      component: () => import('./views/dashboard_empleado'),
      beforeEnter: ifAuthenticated
    },
    {
      path: '/comision/consulta-individual',
      name: 'comision-individual',
      meta: { header: 'Comisión' },
      component: () => import('./views/comisiones/comision_individual'),
      beforeEnter: ifAuthenticated
    },
    {
      path: '/comision/consulta-masiva',
      name: 'comision-masiva',
      meta: { header: 'Comisión' },
      component: () => import('./views/comisiones/comision_masiva'),
      beforeEnter: ifAuthenticated
    },
    {
      path: '/comision/incentivo',
      name: 'comision-incentivo',
      meta: { header: 'Comisión' },
      component: () => import('./views/comisiones/comision_incentivo'),
      beforeEnter: ifAuthenticated
    },
    {
      path: '/comision/cobros-facturas',
      name: 'comision-cobros',
      meta: { header: 'Comisión' },
      component: () => import('./views/comisiones/comision_numeros_cobrados'),
      beforeEnter: ifAuthenticated
    },
    {
      path: '/activacion',
      name: 'Activacion',
      meta: { header: 'Activación' },
      component: () => import('./views/activacion'),
      beforeEnter: ifAuthenticated
    },
    {
      path: '/consulta',
      name: 'Consulta',
      meta: { header: 'Consulta' },
      component: () => import('./views/consulta'),
      beforeEnter: ifAuthenticated
    },
    {
      path: '/facturas',
      name: 'Facturas',
      meta: { header: 'Facturas' },
      component: () => import('./views/facturas'),
      beforeEnter: ifAuthenticated
    },
    {
      path: '/reclamacion/consulta',
      name: 'reclamacion-consulta',
      meta: { header: 'Reclamaciones' },
      component: () => import('./views/reclamaciones/reclamacion_consulta'),
      beforeEnter: ifAuthenticated
    },
    {
      path: '/reclamacion/masiva',
      name: 'reclamacion-masiva',
      meta: { header: 'Reclamaciones' },
      component: () => import('./views/reclamaciones/reclamacion_masiva'),
      beforeEnter: ifAuthenticated
    },
    {
      path: '/reclamaciones-empleado',
      name: 'reclamacion-empleado',
      meta: { header: 'Reclamaciones' },
      component: () => import('./views/reclamaciones/reclamacion_consulta_empleado'),
      beforeEnter: ifAuthenticated
    },
    {
      path: '/reclamacion/consulta-empleado',
      name: 'reclamacion-consulta-empleado',
      meta: { header: 'Reclamaciones' },
      component: () => import('./views/reclamaciones/reclamacion_consulta_empleado'),
      beforeEnter: ifAuthenticated
    },
    {
      path: '/reclamacion/masiva-empleado',
      name: 'reclamacion-masiva-empleado',
      meta: { header: 'Reclamaciones' },
      component: () => import('./views/reclamaciones/reclamacion_masiva_empleado'),
      beforeEnter: ifAuthenticated
    },
    {
      path: '/esquema/comision',
      name: 'esquema-comision',
      meta: { header: 'Esquemas' },
      component: () => import('./views/esquemas/esquema_comision'),
      beforeEnter: ifAuthenticated
    },
    {
      path: '/esquema/detalle-por-producto',
      name: 'esquema-detalle-por-producto',
      meta: { header: 'Esquemas' },
      component: () => import('./views/esquemas/esquema_tarifario'),
      beforeEnter: ifAuthenticated
    },
    {
      path: '/esquema/calculadora',
      name: 'esquema-calculadora',
      meta: { header: 'Esquemas' },
      component: () => import('./views/esquemas/calculadora_empleado'),
      beforeEnter: ifAuthenticated
    },
    {
      path: '/reportes/corte',
      name: 'reporte-comision',
      meta: { header: 'Reportes' },
      component: () => import('./views/reportes/reporte_comision'),
      beforeEnter: ifAuthenticated
    },
    {
      path: '/reportes/empleado',
      name: 'ReporteEmpleado',
      meta: { header: 'Reportes' },
      component: () => import('./views/reportes/reporte_empleado'),
      beforeEnter: ifAuthenticated
    },

    {
      path: '/reportes/general',
      name: 'ReporteGeneral',
      meta: { header: 'Reportes' },
      component: () => import('./views/reportes/reporte_general'),
      beforeEnter: ifAuthenticated
    },
    {
      path: '/reportes/creditos-cobros',
      name: 'ReporteCreditoYCobro',
      meta: { header: 'Reportes' },
      component: () => import('./views/reportes/reporte_credito_cobro'),
      beforeEnter: ifAuthenticated
    },
    {
      path: '/mantenimiento',
      name: 'DocumentoMantenimiento',
      meta: { header: 'Documentos' },
      component: () => import('./views/documentos_mantenimiento'),
      beforeEnter: ifAuthenticated
    },
    {
      path: '/ayuda',
      name: 'Ayuda',
      meta: { header: 'Ayuda' },
      component: () => import('./views/ayuda'),
      beforeEnter: ifAuthenticated
    },
    {
      path: '/perfil',
      name: 'Perfil',
      meta: { header: 'Perfil' },
      component: () => import('./views/perfil'),
      beforeEnter: ifAuthenticated
    },
    {
      path: '/login',
      name: 'Login',
      component: () => import('./views/login'),
      props: (route) => ({ usr: route.query.usr, app: route.query.app, token: route.query.token, url: route.query.url }),
      beforeEnter: ifNotAuthenticated
    },
    {
      path: '/registro',
      name: 'Registro',
      component: () => import('./views/login_registro'),
      props: (route) => ({ usr: route.query.usr, app: route.query.app, token: route.query.token, url: route.query.url }),
      beforeEnter: ifNotAuthenticated
    },
    {
      path: '/restaurar-clave',
      name: 'CambiarClave',
      component: () => import('./views/login_restaurar_clave'),
      props: (route) => ({ usr: route.query.usr, app: route.query.app, token: route.query.token, url: route.query.url }),
      beforeEnter: ifNotAuthenticated
    },
    {
      path: '/recuperar-clave',
      name: 'CredencialesOlvidadas',
      component: () => import('./views/login_solicitud_recuperar_clave'),
      props: (route) => ({ usr: route.query.usr, app: route.query.app, token: route.query.token, url: route.query.url }),
      beforeEnter: ifNotAuthenticated
    },
    {
      path: '/valida-correo',
      name: 'ValidarCorreo',
      component: () => import('./views/login_valida_correo'),
      props: (route) => ({ usr: route.query.usr, app: route.query.app, token: route.query.token, url: route.query.url }),
      beforeEnter: ifNotAuthenticated
    },
    {
      path: '/desbloqueo-cuenta',
      name: 'DesbloquearCuenta',
      component: () => import('./views/login_valida_correo_desbloqueo'),
      props: (route) => ({ usr: route.query.usr, app: route.query.app, token: route.query.token, url: route.query.url }),
      beforeEnter: ifNotAuthenticated
    },
    {
      path: '/logout',
      name: 'Logout',
      component: () => import('./views/logout'),
      beforeEnter: ifAuthenticated
    },
    {
      path: '/asociados',
      name: 'Asociados',
      component: () => import('./views/configuracion_asociados'),
      beforeEnter: ifComisiones
    },
    {
      path: '/configuracion',
      name: 'Configuracion',
      meta: { header: 'Configuración' },
      component: () => import('./views/configuracion'),
      beforeEnter: ifComisiones
    },
    {
      path: '/empleado',
      name: 'empleado',
      meta: { header: 'Configuración' },
      component: () => import('./views/configuracion_empleados'),
      beforeEnter: ifAuthenticated
    }
  ],
  mode: 'hash'
})
