<template>
  <v-row>
    <!-- <v-snackbar v-model="errorEnabled" color="error" :timeout="errorTimeout" vertical top>
      {{errorMessage}}
      <v-btn dark flat @click.native="closeError">Close</v-btn>
    </v-snackbar> -->
    <!-- <v-snackbar v-model="messageEnabled" color="success" vertical top>
      {{message}}
      <v-btn dark flat @click.native="closeMessage">Close</v-btn>
    </v-snackbar> -->
    <vue-snotify></vue-snotify>
  </v-row>
</template>

<script>
import { SnotifyToast } from 'vue-snotify'

export default {
  data () {
    return {
      errorEnabled: false,
      messageEnabled: false,
      errorTimeout: 15000,
      messageTimeout: 5000
    }
  },
  methods: {
    onClose () {
      this.$emit('dismissed')
    },
    closeMessage () {
      this.$store.dispatch('clearMessage')
    },
    closeError () {
      this.$store.dispatch('clearError')
    }
  },
  computed: {
    error () {
      return this.$store.getters.error
    },
    errorMessage () {
      let mensaje = ''
      if (this.$store.getters.error != null) {
        
        if (this.$store.getters.errormessage) {
          mensaje = mensaje + this.$store.getters.errormessage + '. '
        }
        if (this.$store.getters.error.response && this.$store.getters.error.response.statusText) {
          mensaje = mensaje + this.$store.getters.error.response.statusText + '. '
        }
        if (this.$store.getters.error.message) {
          mensaje = mensaje + this.$store.getters.error.message + '. '
        }
      }
      return mensaje
    },
    message () {
      return this.$store.getters.message
    },
    showError () {
      return this.$store.getters.error != null
    },
    showMessage () {
      return this.$store.getters.message != null
    }
  },
  mounted () {
    /* this.$snotify.config.toast.on('hidden', (toast) => {
      this.closeMessage()
      this.closeError()
    }) */
    /* SnotifyToast.on('hidden', (toast) => {
      this.closeMessage()
      this.closeError()
    }) */
  },
  watch: {
    showError (newValue) {
      this.errorEnabled = newValue
      // snotify
      if (this.errorMessage) {
        this.$snotify.error(this.errorMessage, 'Error', {
          timeout: this.errorTimeout,
          showProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          position: 'rightBottom',
          type: SnotifyToast
        }).on('hidden', () => {
          this.closeError()
        })
      }
    },
    showMessage (newValue) {
      this.messageEnabled = newValue
      // snotify
      if (this.message) {
        let msgTitle = ''
        let msgBody = ''
        let msgType = 'success'
        if (typeof this.message === 'string') {
          msgBody = this.message
        } else {
          msgTitle = this.message.title
          msgBody = this.message.body
          if (this.message.type) {
            msgType = this.message.type
          }
        }
        if (msgType === 'confirm') {
          this.$snotify.success(msgBody, msgTitle, {
            type: msgType,
            timeout: this.messageTimeout,
            showProgressBar: false,
            closeOnClick: false,
            pauseOnHover: true,
            position: 'rightBottom',
            buttons: [
              {text: 'Aceptar', action: (toast) => { this.$snotify.remove(toast.id) }, bold: true}
            ]
          }).on('hidden', () => {
            this.closeMessage()
          })
        } else if (msgType === 'warning') {
          this.$snotify.warning(msgBody, msgTitle, {
            type: msgType,
            timeout: this.messageTimeout,
            showProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            position: 'rightBottom'
          }).on('hidden', () => {
            this.closeMessage()
          })
        } else {
          this.$snotify.success(msgBody, msgTitle, {
            type: msgType,
            timeout: this.messageTimeout,
            showProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            position: 'rightBottom'
          }).on('hidden', () => {
            this.closeMessage()
          })
        }
      }
    },
    messageEnabled () {
      if (this.messageEnabled === false && this.$store.getters.message != null) {
        this.closeMessage()
      }
    }
  }
}
</script>

<style>
  .snotifyToast.animated.snotify-success.fadeIn {
    border-radius: 0px;
  }
  .snotifyToast.animated.snotify-error.fadeIn {
    border-radius: 0px;
  }
  .snotifyToast__title {
    font-size: 20px;
  }
  .snotifyToast__body {
    font-size: 15px;
  }
  .snotify-success .snotifyToast__body {
    color: aliceblue;
  }
</style>
