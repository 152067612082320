import Vue from 'vue'
import Vuetify from 'vuetify/lib'
// import colors from 'vuetify/es5/util/colors'

Vue.use(Vuetify)

const opts = {
  icons: {
    iconfont: 'mdi'
  },
  theme: {
    themes: {
      light: {
        primary: "#ff3e4e",
        info: "#17a2b8",
        warning: "#ffba00",
        success: "#46c93a",
        error: "#ff3e4e"
      },
    },
  },
}

export default new Vuetify(opts)
