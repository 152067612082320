import Vue from 'vue'
import { store } from './store'
import App from './App'
import router from './router'
import vuetify from './plugins/vuetify'
import { sync } from 'vuex-router-sync'
import '@mdi/font/css/materialdesignicons.min.css'
import Snotify from 'vue-snotify'
import 'vue-snotify/styles/material.css'
import { global } from './api/global'
import '@fortawesome/fontawesome-free/css/all.css'
import JsonCSV from 'vue-json-csv'
import '@/styles/overrides.sass'

// filters
import DateFilter from './filters/date'
import HourFilter from './filters/hour'
import CurrencyFilter from './filters/currency'
import NumericFilter from './filters/numeric'
import PhoneFilter from './filters/telephone'

// shared
// import AlertCmp from './Shared/Alert'
import AlertComp from './components/alert'
import DateSelector from './components/date_selector'

Vue.component('app-alert', AlertComp)
Vue.component('date-selector', DateSelector)
Vue.component('downloadCsv', JsonCSV)

// snotify
Vue.use(Snotify)

// Sync Vue router and the Vuex store
sync(store, router)

Vue.config.productionTip = false

Vue.filter('date', DateFilter)
Vue.filter('hour', HourFilter)
Vue.filter('currency', CurrencyFilter)
Vue.filter('numeric', NumericFilter)
Vue.filter('phone', PhoneFilter)

/* eslint-disable no-new */
new Vue({
  router,
  store,
  vuetify,
  global,
  render: h => h(App)
}).$mount('#app')
