import { global } from './global'

let _baseurl = global.baseApiUrl + 'seguridad/'

export default {
  autenticaToken(username, token) {
    return global.axios.get(_baseurl + 'autentica-token?usuario=' + username + '&token=' + token)
  },
  getAsociadoByTarjeta(tarjeta) {
    return global.axios.get(_baseurl + 'get-asociado-by-tarjeta?tarjeta=' + tarjeta)
  },
  getRol(username) {
    return global.axios.get(_baseurl + 'get-rol?usuario=' + username)
  },
  getRolDescripcion(username) {
    return global.axios.get(_baseurl + 'get-rol-descripcion?usuario=' + username)
  },
  getAccesos() {
    return global.axios.get(_baseurl + 'get-acceso')
  },
  getUrlUserportal() {
    return global.axios.get(_baseurl + 'get-url-userportal')
  },
  getCurrentIISUser() {
    return global.axios.get(_baseurl + 'get-user-information')
  },
  getGetDashboardPermisos() {
    return global.axios.get(_baseurl + 'get-dashboard-permisos')
  },
  getversion() {
    return global.axios.get(_baseurl + 'get-versiones')
  },
  CambiarClave (data) {
    return global.axios.post(_baseurl + 'cambiar-clave', data)
  },
}
