<template>
  <v-menu
    ref="menu"
    :close-on-content-click="false"
    v-model="menu"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="200px"
    :return-value.sync="value"
  >
  <template v-slot:activator="{ on }">
    <v-text-field
      slot="activator"
      :label="getLabel"
      v-model="value"
      readonly
      :rules="getRules"
      :clearable="getClearable"
      
      hide-details
      v-on="on"
    ></v-text-field>
   </template>
  <v-date-picker v-model="value" color="primary" locale="es-Us" @input="$refs.menu.save(value)" no-title scrollable></v-date-picker>
</v-menu>
</template>

<script>
export default {
  props: ['fecha', 'label', 'rules'],
  data () {
    return {
      value: null,
      menu: false
    }
  },
  computed: {
    getLabel () {
      return this.label ? this.label : 'Fecha'
    },
    getRules () {
      return this.rules ? this.rules : []
    },
    getClearable () {
      return this.clearable ? this.clearable : true
    }
  },
  watch: {
    fecha: {
      handler: function () {
        if (this.fecha !== undefined) {
          this.value = this.fecha
        }
      },
      immediate: true
    },
    value: {
      handler: function (newVal) {
        this.$emit('update:fecha', newVal)
      },
      immediate: true
    }
  }
}
</script>