<template>
  <v-app>
    <div v-if="!logginPage.includes($route.path)">
      <navegation-drawer />
      <app-bar />
    </div>
    <v-main>
      <v-container fluid>
        <router-view :key="$route.fullPat" />
      </v-container>
    </v-main>
    <app-alert></app-alert>
  </v-app>
</template>

<script>
export default {
  name: "App",
  components: {
    navegationDrawer: () => import("@/components/core/navegation_drawer"),
    appBar: () => import("@/components/core/app_bar"),
    // footerBar:()=>import("@/components/core/footer_bar")
  },
  data: () => ({
    logginPage: [
      "/login",
      "/recuperar-clave",
      "/restaurar-clave",
      "/registro",
      "/valida-correo",
      "/desbloqueo-cuenta",
    ],
  }),
  computed: {
    error() {
      return this.$store.getters.error;
    },
    errormessage() {
      return this.$store.getters.errormessage;
    },
  },
  methods: {},
  props: {
    source: String,
  },
  created() {
    // this.$vuetify.theme.themes.light.primary = "#E4322B";
    // this.$vuetify.theme.themes.light.info = "#009DC4";
    // this.$vuetify.theme.themes.light.secondary = "#a6ce39";
    // this.$vuetify.theme.themes.light.accent = "#878887";
    // this.$vuetify.theme.themes.light.error = "#E4322B";
  },
};
</script>