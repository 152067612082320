import Vue from 'vue'
import Vuex from 'vuex'
import router from './router'
import apiAutenticacion from './api/autenticacion'
import apiProducto from './api/productos'
import apiAsociado from './api/asociados'
import apiSeguridad from './api/seguridad'
import apiEmpleado from './api/empleados'
import apiHome from './api/home'
import moment from 'moment'

Vue.use(Vuex)

export const store = new Vuex.Store({
  state: {
    user: {
      nombre: null,
      username: '',
      DisplayUser:'',
      asociado: {
        id: null,
        codigo: null,
        canalAsociadoId: null,
        asociadoComisionGroupID: null,
      },
      sucursales: [{ CanalSucursalId: 0, CodSucursal: '' }],
      canalid: null,
      canal: '',
      tipoCanal: null,
      tarjeta: '',
      rol: '',
      perfil: '',
      empleadoid: null,
      accesos: [{ key: 0 }],
      corteId: null,
      corte: {},
      seleccion: {
        empleados: [],
        gestionados: [],
        canales: []
      },
      segmento: []
    },
    asociados: [],
    canal: '',
    token: '',
    loggedIn: false,
    error: null,
    errormessage: null,
    message: null,
    loading: false,
    tipoProducto: [],
    cortes: [],
    periodos: [],
    pawUrl: null,
    leftdrawer: null,
    breadcrumbs: [],
    canalesInternos: [],
    version: null,
    passwordFormat:null,
    canalesExternos: [],
    parentKey: null,
    updateCorte: null
  },
  mutations: {
    setUser(state, payload) {
      state.user = payload
      try {
        sessionStorage.setItem('username', payload.username)
      } catch (e) {
        // empty
      }
    },
    setTipoProductos(state, payload) { state.tipoProducto = payload },
    setProductos(state, payload) { state.productos = payload },
    setLoading(state, payload) { state.loading = payload },
    setError(state, payload) { state.error = payload },
    setErrorMessage(state, payload) { state.errormessage = payload },
    setMessage(state, payload) { state.message = payload },
    clearError(state) {
      state.error = null
      state.errormessage = null
    },
    clearMessage(state) { state.message = null },
    loggedIn(state, data) {
      state.loggedIn = true
      state.user = (data.name || '').split(' ')[0] || 'Hello'
      let redirectTo = state.route.query.redirect || '/'
      router.push(redirectTo)
    },
    loggedOut(state) { state.loggedIn = false },
    setCortes(state, payload) { state.cortes = payload },
    setCorte(state, payload) { state.user.corte = payload; state.user.corteId = payload.CorteId; state.user.canalid = payload.CanalId },
    setPeriodos(state, payload) { state.periodos = payload },
    setAsociados(state, payload) { state.asociados = payload },
    setCanalesInternos(state, payload) { state.canalesInternos = payload },
    setCanalesExternos(state, payload) { state.canalesExternos = payload },
    setPAWUrl(state, payload) { state.pawUrl = payload },
    setToken(state, payload) {
      state.token = payload
      sessionStorage.setItem('token', payload)
    },
    update_current_user(state, user) {
      state.currentUser = user
    },
    setLeftDrawer(state, payload) { state.leftdrawer = payload },
    setBreadcrumbs(state, payload) { state.breadcrumbs = payload },
    setCanal(state, payload) { state.canal = payload },
    setSegmento(state, payload) { state.user.segmento = payload },
    setVersion(state, payload) {state.version = payload},
    setPasswordFormat(state, payload) {state.passwordFormat = payload},
    setParentKey(state, payload) { state.parentKey = payload },
  },
  getters: {
    user: state => state.user,
    routerAcceso: state => state.routerAcceso,
    token: state => state.token,
    tipoProducto: state => state.tipoProducto,
    productos: state => state.productos,
    loading: state => state.loading,
    error: state => state.error,
    errormessage: state => state.errormessage,
    message: state => state.message,
    cortes: state => state.cortes,
    periodos: state => state.periodos,
    asociados: state => state.asociados,
    canalesInternos: state => state.canalesInternos,
    canalesExternos: state => state.canalesExternos,
    pawUrl: state => state.pawUrl,
    version: state => state.version,
    passwordFormat: state => state.passwordFormat,
    isAuthenticated(state) {
      let resultado = (state.user !== undefined && state.user !== null)
      if (resultado) {
        resultado = (state.user.username !== '')
      }
      return resultado
    },
    leftdrawer: state => state.leftdrawer,
    breadcrumbs: state => state.breadcrumbs,
    canal: state => state.canal,
    parentKey: state => state.parentKey
  },
  actions: {
    logout({ commit }) { commit('setUser', null) },
    setUser({ commit }, user) { commit('setUser', user) },
    clearError({ commit }) { commit('clearError') },
    clearMessage({ commit }) { commit('clearMessage') },
    setLoading({ commit }, payload) { commit('setLoading', payload) },
    setError({ commit }, payload) { commit('setError', payload) },
    setErrorMessage({ commit }, payload) { commit('setErrorMessage', payload) },
    setMessage({ commit }, payload) { commit('setMessage', payload) },
    setPAWUrl({ commit }, payload) { commit('setPAWUrl', payload) },
    setToken({ commit }, payload) { commit('setToken', payload) },
    setLeftDrawer({ commit }, payload) { commit('setLeftDrawer', payload) },
    setBreadcrumbs({ commit }, payload) { commit('setBreadcrumbs', payload) },
    setCorte({ commit }, payload) { commit('setCorte', payload) },
    setCanal({ commit }, payload) { commit('setCanal', payload) },
    setVersion({ commit }, payload) { commit('setVersion', payload) },
    setPasswordFormat({ commit }, payload) { commit('setPasswordFormat', payload) },
    setParentKey({ commit }, payload) { commit('setParentKey', payload) },
    getTipoProductos({ commit }) {
      commit('setLoading', true)
      commit('clearError')
      apiProducto.getTipoProductos()
        .then(response => {
          commit('setLoading', false)
          commit('setTipoProductos', response.data)
        })
        .catch(
          (error) => {
            commit('setLoading', false)
            commit('setError', error)
            commit('setErrorMessage', 'Error buscando tipos de productos')

          }
        )
    },
    getProductos({ commit }) {
      commit('setLoading', true)
      commit('clearError')
      apiProducto.GetProductos()
        .then(response => {
          commit('setLoading', false)
          commit('setProductos', response.data)
        })
        .catch(
          (error) => {
            commit('setLoading', false)
            commit('setError', error)
            commit('setErrorMessage', 'Error buscando productos')

          }
        )
    },
    getSegmento({ commit, getters }) {
      commit('setLoading', true)
      commit('clearError')
      apiEmpleado.getSegmento(Array.from(getters.user.seleccion.canales))
        .then(response => {
          commit('setLoading', false)
          commit('setSegmento', response.data)
        })
        .catch(
          (error) => {
            commit('setLoading', false)
            commit('setError', error)
            commit('setErrorMessage', 'Error buscando segmento')

          }
        )
    },
    async getCortes({ commit, getters }) {
      commit('setLoading', true)
      commit('clearError')

      var _cortes = [];
      if (getters.user.rol === 'Empleado' || getters.user.empleadoid) {
        await apiEmpleado.getCortes(getters.user.empleadoid).then(response => {
          _cortes = response.data;
          _cortes.forEach(item => {
            item.fechas = item.Nombre.replace('WEE', ' - ') + ' (' + moment(item.StartDate).format('DD/MM/YYYY') + ' - ' + moment(item.EndDate).format('DD/MM/YYYY') + ')'
          })
        }).catch(error => {
          commit('setLoading', false)
          commit('setError', error)
          commit('setErrorMessage', 'Error buscando cortes')
        })
      }
      else if (getters.user.canalid) {
        await apiAsociado.getCortes(getters.user.canalid).then(response => {
          _cortes = response.data
          _cortes.forEach(item => {
            item.fechas = item.Nombre.replace('WEE', ' - ') + ' (' + moment(item.StartDate).format('DD/MM/YYYY') + ' - ' + moment(item.EndDate).format('DD/MM/YYYY') + ')'
          })
        }).catch(error => {
          commit('setLoading', false)
          commit('setError', error)
          commit('setErrorMessage', 'Error buscando cortes')
        }
        )
      }

      commit('setLoading', false)
      commit('setCortes', _cortes)
    },
    async getPeriodos({ commit }) {
      commit('setLoading', true)
      commit('clearError')

      var _periodos = [];
      await apiAsociado.getPeriodo().then(response => {
        _periodos = response.data;
      }).catch(error => {
        commit('setLoading', false)
        commit('setError', error)
        commit('setErrorMessage', 'Error buscando periodos')
      });

      commit('setLoading', false)
      commit('setPeriodos', _periodos)
    },
    async getAsociados({ commit }, payload) {
      commit('setLoading', true)
      commit('clearError')

      var _asociados = [];
      await apiAsociado.getAsociados(payload).then(response => {
        _asociados = response.data;
      }).catch(error => {
        commit('setLoading', false)
        commit('setError', error)
        commit('setErrorMessage', 'Error buscando asociados')
      });

      commit('setLoading', false)
      commit('setAsociados', _asociados)
    },
    async getCanales({ commit }) {
      commit('setLoading', true)
      commit('clearError')

      var _canalesInternos = [];
      await apiHome.getCanalesInternos().then(response => {
        _canalesInternos = response.data;
      }).catch(error => {
        commit('setLoading', false)
        commit('setError', error)
        commit('setErrorMessage', 'Error buscando canales')
      });

      var _canalesExternos = [];
      await apiHome.getCanalesExternos().then(response => {
        _canalesExternos = response.data;
      }).catch(error => {
        commit('setLoading', false)
        commit('setError', error)
        commit('setErrorMessage', 'Error buscando canales')
      });

      commit('setLoading', false)
      commit('setCanalesInternos', _canalesInternos)
      commit('setCanalesExternos', _canalesExternos)
    },
    async searchFormatPassword({ dispatch }){
      var requestFormatPassword = await apiAutenticacion.getFormatPassword()
      let passwordFormat = requestFormatPassword.data;

      dispatch('setPasswordFormat', passwordFormat);
    },
    async setUserData({ dispatch }, payload) {
      try {
        var _canal = "";
        let response = await apiSeguridad.getCurrentIISUser()
        let info = response.data
        let user = {
          asociado: {
            id: info.AsociadoId,
            socioId: info.SocioId,
            codigo: info.CodigoAsociado,
            canalAsociadoId: info.CanalAsociadoId,
            asociadoComisionGroupID: info.AsociadoComisionGroupID
          },
          nombre: info.Nombre,
          canalid: info.CanalId,
          rol: info.Rol,
          empleadoid: info.EmpleadoID,
          username: info.username,
          tipoCanal: info.TipoCanal,
          DisplayUser: info.DisplayUser
        }
        let version = null;

        if (user.asociado.canalAsociadoId) {
          apiAsociado.getSucursalesByCanalAsociadoID(user.asociado.canalAsociadoId)
            .then(response => {
              user.sucursales = response.data
            })
        }

        _canal = user.asociado.id ? "externo" : user.empleadoid ? "interno" : "";

        // if (user.asociado.id) {
        //   _canal = "externo";
        // }

        // if (user.empleadoid) {
        //   _canal = "interno";
        // }

        var request = await apiSeguridad.getAccesos()
        user.accesos = request.data
      
        if (user.seleccion) {
          request = await apiEmpleado.getSegmento(user.seleccion.canales);
          user.segmento = request.data;
        }

        request = await apiSeguridad.getversion()
        version = request.data;

        request = await apiSeguridad.getGetDashboardPermisos();
        user.AcessoDashBoard = request.data

        //Password request

        var requestFormatPassword = await apiAutenticacion.getFormatPassword()
        let passwordFormat = requestFormatPassword.data;

        dispatch('setUser', user)
        dispatch('getTipoProductos')
        dispatch('getCortes')
        dispatch('getPeriodos')
        dispatch('getAsociados', user.canalid)
        dispatch('getCanales', user.canalid)
        dispatch('setCanal', _canal)
        dispatch('setVersion', version)
        dispatch('setPasswordFormat', passwordFormat)

        if (payload) {
          router.push('/')
        } else {
          router.push('/login')
        }
      } catch (error) {
        dispatch('setError', error)
        router.push('/login')
      }
    }
  }
})
