import {global} from './global'
import {store} from '@/store'

let _baseurl = global.baseApiUrl + 'producto/'

export default {
  getTipoProductos () {
    return global.axios.get(_baseurl + 'get-tipo-productos')
  },
  getFamiliaProducto () {
    return global.axios.get(_baseurl + 'get-familia-producto')
  },
  GetTipoProductosCalidadVenta () {
    return global.axios.get(_baseurl + 'get-tipo-producto-calidad-venta')
  },
  GetProductos () {
    return global.axios.get(_baseurl + 'get-producto')
  },
  GetProductosByProductoTipoID (ProductoTipoID) {
    return global.axios.get(_baseurl + 'get-producto-by-producto-tipo-id?canalid=' + store.getters.user.canalid + '&productotipoid=' + ProductoTipoID)
  },
  GetProductCodesByTipoID (ProductoTipoID) {
    return global.axios.get(_baseurl + 'get-product-code-by-tipo-id?canalID=' + store.getters.user.canalid + '&ProductoTipoID=' + ProductoTipoID)
  },
  GetProductCodesByProductoID (ProductID) {
    return global.axios.get(_baseurl + 'get-product-code-by-producto-id?ProductID=' + ProductID)
  }
}
