import {global} from './global'

let _host = global.baseApiUrl + 'account/'

export default {
  generateCaptCha () {
    return global.axios.get(_host + 'generate-captcha')
  },
  validarTokenRecuperacion (token) {
    return global.axios.post(_host + 'validar-token-recuperacion', token)
  },
  CambiarClave (data) {
    return global.axios.post(_host + 'cambiar-clave', data)
  },
  recuperarClave (data) {
    return global.axios.post(_host + 'recuperar-clave', data)
  },
  login (data) {
    return global.axios.post(_host + 'login', data)
  },
  logout () {
    return global.axios.post(_host + 'logout')
  },
  registrarse (data) {
    return global.axios.post(_host + 'registrarse', data, {headers: {'content-type': 'application/json'}})
  },
  validarCorreo (data) {
    return global.axios.post(_host + 'validar-correo', data)
  },
  validarCorreoDesbloqueo (data) {
    return global.axios.post(_host + 'validar-correo-desbloqueo', data)
  },
  getFormatPassword() {
    return global.axios.get(_host + 'get-formatPassword')
  },
}
